/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import Plaatjie from '@ubo/plaatjie'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Pagination } from 'swiper'

// Elements
import ParseContent from 'components/shared/ParseContent'
import NavLink from 'components/shared/NavLink'

// Images
import ArrowRight from 'img/arrowright.inline.svg'
import { graphql, useStaticQuery } from 'gatsby'

interface RecentNewsProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_RecentNews
}

const Content = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    @media (min-width: 1400px) {
      font-size: 48px;
    }
  }

  & p {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;

    @media (min-width: 1400px) {
      -webkit-line-clamp: 2;
    }
    @media (max-width: 1399px) {
      -webkit-line-clamp: 3;
    }
    @media (max-width: 1199px) {
      -webkit-line-clamp: 4;
    }
    @media (max-width: 991px) {
      -webkit-line-clamp: 3;
    }
    @media (max-width: 767px) {
      -webkit-line-clamp: 5;
    }
    @media (max-width: 575px) {
      -webkit-line-clamp: 3;
      font-size: 14px;
    }
  }
`

const Image = styled(Plaatjie)`
  z-index: -1;
  @media (min-width: 992px) {
    height: 300px;
  }
  @media (max-width: 767px) {
    height: 220px;
  }
  @media (max-width: 575px) {
    height: 100px;
  }
`

const Label = styled.div`
  right: 30px;
  font-family: ${({ theme }) => theme.font.family.primaryMedium};
  color: ${({ theme }) => theme.color.light};
  background-color: ${({ theme }) => theme.color.primary};
  font-size: 14px;
  @media (min-width: 576px) {
    padding: 0.8rem 1.9rem;
  }
  @media (max-width: 575px) {
    padding: 0.5rem 1.6rem;
  }
`

const Description = styled.div`
  z-index: 2;
  background-color: ${({ theme }) => theme.color.light};
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0px 4px 1px;
  margin-top: -3.5rem;

  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    font-family: ${({ theme }) => theme.font.family.primaryBold};
    font-size: 18px;
    margin-bottom: 1rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    @media (min-width: 1200px) {
      min-height: 64px;
      -webkit-line-clamp: 3;
    }

    @media (max-width: 1199px) {
      -webkit-line-clamp: 2;
      min-height: 42px;
    }

    @media (max-width: 575px) {
      font-size: 14px;
      min-height: 32px;
    }
  }

  & svg {
    width: 17.35px;
    height: 13.81px;
    & path {
      fill: ${({ theme }) => theme.color.primary};
    }
  }
`

const SwiperContainer = styled.div<{ marginLeft: number }>`
  max-width: initial !important;
  position: relative;
  width: calc(100% - ${({ marginLeft }) => marginLeft}px + 0.75rem) !important;
  margin-left: ${({ marginLeft }) => marginLeft}px !important;

  & .swiper {
    padding-right: 1.5rem;
  }
`

const RecentNews: React.FC<RecentNewsProps> = ({ fields }) => {
  const {
    allWpPost,
    // eslint-disable-next-line
  } = useStaticQuery<GatsbyTypes.recentNewsQueryQuery>(graphql`
    query recentNewsQuery {
      allWpPost(sort: { order: DESC, fields: date }, limit: 3) {
        edges {
          node {
            ...generalPostFragment
          }
        }
      }
    }
  `)

  const posts: unknown = allWpPost.edges

  const [currentIndex, setCurrentIndex] = useState<number | null>(null)
  const [marginLeft, setMarginLeft] = React.useState<number | null>(null)
  const containerRef = React.useRef(null)

  const handleResize = () => {
    if (!containerRef.current) {
      return
    }

    const nodeStyle = window.getComputedStyle(containerRef.current)
    const nodeMarginRight = nodeStyle.getPropertyValue('margin-left')

    setMarginLeft(Number(nodeMarginRight.replace('px', '')))
  }

  React.useEffect(() => {
    handleResize()

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', () => handleResize)
    }
  }, [])

  return (
    <section className="mb-5 pb-lg-5">
      <div className="container" ref={containerRef}>
        <Content content={fields.description} className="mb-5" />
        <div className="row d-none d-lg-flex">
          {posts?.map((post, index: number) => {
            const current: boolean = index === currentIndex

            return (
              // eslint-disable-next-line react/no-array-index-key
              <div className="col-sm-6 col-lg-4 mb-5 mb-lg-0" key={index}>
                <div className="position-relative">
                  <Label className="position-absolute">
                    <span>NIEUWS</span>
                  </Label>
                  <Image
                    image={post?.node?.postdetail?.highlightedimage}
                    alt=""
                  />
                </div>
                <motion.div
                  onHoverStart={() => setCurrentIndex(index)}
                  onHoverEnd={() => setCurrentIndex(null)}
                >
                  <NavLink to={post?.node?.uri || '/'}>
                    <Description className="position-relative p-4 mx-4">
                      <h2>{post?.node?.title}</h2>
                      <Content
                        content={post?.node?.postdetail?.shortdescription}
                      />
                      <motion.div
                        animate={current ? { x: '90%' } : { x: 0 }}
                        transition={{
                          duration: 0.5,
                          type: 'spring',
                          stiffness: 40,
                        }}
                        className="d-flex"
                      >
                        <ArrowRight className="mt-4" />
                      </motion.div>
                    </Description>
                  </NavLink>
                </motion.div>
              </div>
            )
          })}
        </div>
      </div>
      {marginLeft !== null && (
        <SwiperContainer
          className="container d-lg-none pb-5 "
          marginLeft={marginLeft}
        >
          <Swiper
            breakpoints={{
              768: {
                slidesPerView: 2.25,
                spaceBetween: 30,
              },
              450: {
                slidesPerView: 1.75,
                spaceBetween: 20,
              },
              0: {
                slidesPerView: 1.25,
                spaceBetween: 10,
              },
            }}
          >
            {posts?.map((post, index) => {
              const current: boolean = index === currentIndex

              return (
                <SwiperSlide key={index}>
                  <div className="position-relative">
                    <Label className="position-absolute">
                      <span>NIEUWS</span>
                    </Label>
                    <Plaatjie
                      image={post?.node?.postdetail?.highlightedimage}
                      alt=""
                      style={{ height: 200 }}
                    />
                  </div>
                  <motion.div
                    onHoverStart={() => setCurrentIndex(index)}
                    onHoverEnd={() => setCurrentIndex(null)}
                  >
                    <NavLink to={post?.node?.uri || '/'}>
                      <Description className="position-relative p-4 mx-4 mb-4">
                        <h2>{post?.node?.title}</h2>
                        <Content
                          content={post?.node?.postdetail?.shortdescription}
                        />
                        <motion.div
                          animate={current ? { x: '90%' } : { x: 0 }}
                          transition={{
                            duration: 0.5,
                            type: 'spring',
                            stiffness: 40,
                          }}
                          className="d-flex"
                        >
                          <ArrowRight className="mt-4" />
                        </motion.div>
                      </Description>
                    </NavLink>
                  </motion.div>
                </SwiperSlide>
              )
            })}
          </Swiper>
        </SwiperContainer>
      )}
    </section>
  )
}

export default RecentNews
